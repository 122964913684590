export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const OPEN_DROPDOWN = "OPEN_DROPDOWN";
export const CLOSE_DROPDOWN = "CLOSE_DROPDOWN";
export const SUBTRACT_PRODUCT = "SUBTRACT_PRODUCT";
export const INITIALIZE_CART = "INITIALIZE_CART";

const addProductToCart = (state, product) => {
  const color = product.color.toLowerCase();
  const updatedCart = [...state.cart];
  const updatedCount = state.total + 1;
  let updatedPrice = state.totalprice;
  const updatedItemIndex = updatedCart.findIndex(
    (item) => item.id === product.node.id && color in item
  );

  // Find all prodcts with matching id
  const productsWithMatchingId = updatedCart.flatMap((item) =>
    item.id === product.node.id ? item : []
  );

  const productObjectKeys = productsWithMatchingId.reduce((acc, val) => {
    const values = Object.keys(val);
    return acc.concat(values);
  }, []);

  const image = product.node.frontmatter.imagegallery.find(
    (image) => image.productcolor.toLowerCase() === color
  );

  if (updatedItemIndex < 0 || !productObjectKeys.includes(color)) {
    updatedCart.push({
      ...product.node,
      [color]: { quantity: 1, color: product.color },
    });

    // Find if product in this color already exists
  } else {
    const updatedItem = {
      ...updatedCart[updatedItemIndex],
    };
    updatedItem[color].quantity++;
    updatedCart[updatedItemIndex] = updatedItem;
  }
  updatedPrice = updatedPrice += parseInt(product.node.frontmatter.price, 10);
  localStorage.setItem(
    "cart",
    JSON.stringify({
      ...state,
      cart: updatedCart,
      total: updatedCount,
      totalprice: updatedPrice,
      lastAddedProductId: product.node.id,
      lastAddedProductTitle: product.node.frontmatter.title,
      lastAddedProductImage: image,
      lastAddedProductColor: product.color,
      lastAddedProductPrice: product.node.frontmatter.price,
    })
  );
  return {
    ...state,
    cart: updatedCart,
    total: updatedCount,
    totalprice: updatedPrice,
    lastAddedProductId: product.node.id,
    lastAddedProductTitle: product.node.frontmatter.title,
    lastAddedProductImage: image,
    lastAddedProductColor: product.color,
    lastAddedProductPrice: product.node.frontmatter.price,
  };
};

const subtractProductFromCart = (state, product) => {
  const color = product.color.toLowerCase();
  const updatedCart = [...state.cart];
  const updatedCount = state.total - 1;
  let updatedPrice = state.totalprice;

  const updatedItemIndex = updatedCart.findIndex(
    (item) => item.id === product.node.id && color in item
  );

  const updatedItem = {
    ...updatedCart[updatedItemIndex],
  };
  updatedItem[color].quantity--;
  if (updatedItem[color].quantity <= 0) {
    updatedCart.splice(updatedItemIndex, 1);
  } else {
    updatedCart[updatedItemIndex] = updatedItem;
  }
  updatedPrice = updatedPrice -= parseInt(product.node.frontmatter.price, 10);
  localStorage.setItem(
    "cart",
    JSON.stringify({
      ...state,
      cart: updatedCart,
      total: updatedCount,
      totalprice: updatedPrice,
    })
  );
  return {
    ...state,
    cart: updatedCart,
    total: updatedCount,
    totalprice: updatedPrice,
  };
};

const removeProductFromCart = (state, product) => {
  const color = product.color.toLowerCase();
  const updatedCart = [...state.cart];
  let updatedPrice = state.totalprice;

  const updatedItemIndex = updatedCart.findIndex(
    (item) => item.id === product.node.id && color in item
  );
  const quantity = updatedCart[updatedItemIndex][color].quantity;
  const updatedCount = state.total - quantity;
  updatedCart.splice(updatedItemIndex, 1);
  updatedPrice = updatedPrice -=
    parseInt(product.node.frontmatter.price, 10) * quantity;
  if (typeof window !== "undefined") {
    localStorage.setItem(
      "cart",
      JSON.stringify({
        ...state,
        cart: updatedCart,
        total: updatedCount,
        totalprice: updatedPrice,
      })
    );
  }

  return {
    ...state,
    cart: updatedCart,
    total: updatedCount,
    totalprice: updatedPrice,
  };
};

const initializeLocalStorage = (state) => {
  if (typeof window !== "undefined") {
    const storage = JSON.parse(localStorage.getItem("cart"));
    if (storage) {
      state = storage;
    }
  }
  return state;
};

const closeDropdown = (state) => {
  return { ...state, dropDownOpen: false };
};
const openDropdown = (state) => {
  return { ...state, dropDownOpen: true };
};

export const shopReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "INITIALIZE_CART":
      return initializeLocalStorage(state);
    case "ADD_PRODUCT":
      return addProductToCart(state, payload);
    case "SUBTRACT_PRODUCT":
      return subtractProductFromCart(state, payload);
    case "REMOVE_PRODUCT":
      return removeProductFromCart(state, payload);
    case "CLOSE_DROPDOWN":
      return closeDropdown(state);
    case "OPEN_DROPDOWN":
      return openDropdown(state);
    default:
      return state;
  }
};
