import React, { useReducer } from "react";
import { shopReducer } from "./CartReducer";

const initialState = {
  cart: [],
  total: 0,
  totalprice: 0,
  taxRate: 20,
  lastAddedProductId: "",
  lastAddedProductTitle: "",
  lastAddedProductImage: null,
  lastAddedProductColor: "",
  lastAddedProductPrice: 0,
  purchase_country: "SE",
  purchase_currency: "SEK",
};

export const CartContext = React.createContext(initialState);

const CartProvider = (props) => {
  const [globalState, dispatch] = useReducer(shopReducer, initialState);
  return (
    <CartContext.Provider value={{ globalState, dispatch }}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
