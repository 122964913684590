export default {
  colors: {
    text: "#333",
    background: "#fff",
    primary: "#0C1428",
    secondary: "#fafafa",
    details: "#545454",
    error: "#970009",
    black: "#000000",
  },
  fonts: {
    body: "Ginto, sans-serif",
    heading: "Ginto, sans-serif",
  },
  fontWeights: {
    body: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 36, 48, 64, 72],
  space: [0, 4, 8, 16, 24, 32, 64, 98, 128, 256, 512],

  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 1,
      margin: 0,
    },
  },
};
