import { useState } from "react";

export const useDropdown = () => {
  const [open, setOpen] = useState(false);

  function closeDropdown() {
    setOpen(false);
  }

  function openDropdown() {
    setOpen(true);
  }

  return { open, closeDropdown, openDropdown };
};
