import React from "react";
import { useDropdown } from "../hooks/useDropdown";

function getState(open, closeDropdown = () => {}, openDropdown = () => {}) {
  return {
    open,
    closeDropdown,
    openDropdown,
  };
}

export const DropdownContext = React.createContext(getState());

const DropdownProvider = (props) => {
  const { open, closeDropdown, openDropdown } = useDropdown();
  return (
    <DropdownContext.Provider
      value={getState(open, closeDropdown, openDropdown)}
    >
      {props.children}
    </DropdownContext.Provider>
  );
};

export default DropdownProvider;
