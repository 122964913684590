import React from "react";
import propTypes from "prop-types";
import CartProvider from "./src/providers/CartProvider";
import DropdownProvider from "./src/providers/DropdownProvider";

// Put providers here
export const wrapRootElement = ({ element }) => {
  return (
    <CartProvider>
      <DropdownProvider>{element}</DropdownProvider>
    </CartProvider>
  );
};

wrapRootElement.propTypes = {
  element: propTypes.any.isRequired,
};
